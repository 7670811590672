export const getType = (type, colon = true) => {
    const typeMap = {
        cylinder: 'Cylinder',
        bulk: 'Bulk',
        metered: 'Metered',
        autogas: 'Autogas',
    }
    return (typeof typeMap[type] !== 'undefined' ? typeMap[type] + (colon ? ': ' : '') : '');
}
export const getPostcode = (postcode) => {
    if (postcode && postcode.length) {
        return ' - ' + postcode;
    }
    return '';
}
export const filterAccountsCallback = function (account) {
    return (
        (account.accountNumber && account.accountNumber.indexOf(this) > -1)
        || (account.alias && account.alias.toLowerCase().indexOf(this.toLowerCase()) > -1)
        || (account.postcode && account.postcode.toLowerCase().indexOf(this.toLowerCase()) > -1)
    );
}