import Button from 'components/ui/Button';
import url from './url';

const manualStopMessages = {
    message: null,
    set(message) {
        this.message = message;
    },
    hasMessage() {
        return this.message !== null
    },
    getHtml() {
        return this.message ? <div key={this.message.code} dangerouslySetInnerHTML={ { __html: this.message.message } }></div> : '';
    },
    getButtons() {
        if (this.message === null) {
            return '';
        }

        if (! this.message.buttonItems.length) {
            return <Button simple to="/help-support/contact">Contact us</Button>
        }
        return this.message.buttonItems.map((button) => {
            return url.isFull(button.link)
                ? <Button key={ button.key } simple href={ button.link } target="portalExternal">{ button.text }</Button>
                : <Button key={ button.key } simple to={ button.link }>{ button.text }</Button>;

        })
    }
}
export default manualStopMessages;